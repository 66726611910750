<template>
  <div class="c-leaderboard-table">
    <div class="c-leaderboard-table__intro">
      <h3 class="c-leaderboard-table__title">
        Leaderboard bets 🔥

        <div class="c-leaderboard-table__timer">
          <div v-if="leaderboardCategory===0">
            Resets in
            {{ ($store.getters.getMillisecondsRemainingToday > 60000 ? Math.floor($store.getters.getMillisecondsRemainingToday / 1000 / 60 % 60).toString().padStart(2, "0") + ":" : "") + Math.floor($store.getters.getMillisecondsRemainingToday / 1000 % 60).toString().padStart(2, "0")
            }}
          </div>
          <div v-if="leaderboardCategory===1">
            Resets in
            {{ ($store.getters.getMillisecondsRemainingToday > 3600000 ? Math.floor($store.getters.getMillisecondsRemainingToday / 1000 / 60 / 60).toString().padStart(2, "0") + ":" : "") + ($store.getters.getMillisecondsRemainingToday > 60000 ? Math.floor($store.getters.getMillisecondsRemainingToday / 1000 / 60 % 60).toString().padStart(2, "0") + ":" : "") + Math.floor($store.getters.getMillisecondsRemainingToday / 1000 % 60).toString().padStart(2, "0")
            }}
          </div>
          <div v-if="leaderboardCategory===2">
            Resets in
            {{ ($store.getters.getMillisecondsRemainingThisWeek > 3600000 ? Math.floor($store.getters.getMillisecondsRemainingThisWeek / 1000 / 60 / 60).toString().padStart(2, "0") + ":" : "") + ($store.getters.getMillisecondsRemainingThisWeek > 60000 ? Math.floor($store.getters.getMillisecondsRemainingThisWeek / 1000 / 60 % 60).toString().padStart(2, "0") + ":" : "") + Math.floor($store.getters.getMillisecondsRemainingThisWeek / 1000 % 60).toString().padStart(2, "0") }}
          </div>
        </div>
      </h3>
      <div class="c-leaderboard-battle__pool-line">
        <span class="c-leaderboard-table__subtitle">
          💰 Prize pool
        </span>
        <div class="c-leaderboard-battle__pool-update">
          <div class="c-leaderboard-table__pool-update-buttons">
            <button
                    class="c-leaderboard-table__pool-update-btn"
                    :class="{ 'c-btn--c-leaderboard-table__pool-update-btn-selected': leaderboardCategory === 0 }"
                    @click="selectLeaderboard(0)"
            >
              Hourly
            </button>
            <button
                    class="c-leaderboard-table__pool-update-btn"
                    :class="{ 'c-btn--c-leaderboard-table__pool-update-btn-selected': leaderboardCategory === 1 }"
                    @click="selectLeaderboard(1)"
            >
              Daily
            </button>
            <button
                    class="c-leaderboard-table__pool-update-btn"
                    :class="{ 'c-btn--c-leaderboard-table__pool-update-btn-selected': leaderboardCategory === 2 }"
                    @click="selectLeaderboard(2)"
            >
              Weekly
            </button>
          </div>
        </div>
        <span class="c-leaderboard-table__pool">
          {{ $filters.formatNumber(leaderboardPrize) }} Chips
        </span>
      </div>
    </div>
    <div class="c-leaderboard-table__ctn">
      <div class="c-leaderboard-table__head">
        <div class="c-leaderboard-table__row">
          <div class="c-leaderboard-table__row-item is-for-number"></div>
          <div class="c-leaderboard-table__row-item is-for-bettor">
            User
          </div>
          <div class="c-leaderboard-table__row-item is-for-points">
            Points
          </div>
          <div class="c-leaderboard-table__row-item is-for-prize">
            Prize
          </div>
        </div>
      </div>
      <div class="c-leaderboard-table__body">
        <div
          v-for="(item, index) in leaderboard.users"
          :key="index"
          :class="['c-leaderboard-table__row']"
        >
          <div class="c-leaderboard-table__row-item is-for-number">
            <div
              :class="[
                'c-circle c-circle--gray',
                {
                  'c-circle--is-for-me':
                    item.username == $store.getters.getDiscordUsername || item.username == $store.getters.getUserId,
                },
              ]"
            >
              <span class="c-circle__value">{{ (index + 1) }}</span>
            </div>
          </div>

          <img v-if="item.avatar"
               :src="/\/\d$/.test(item.avatar)?item.avatar+'.png':item.avatar"
               class="c-leaderboard-table__row-item-avatar"
               alt=""
               onError="this.onerror=null;this.src='https://cdn.discordapp.com/embed/avatars/4.png';"
          />
          <div
            class="c-leaderboard-table__row-item is-for-bettor is-primary-bold"
            :class="{'c-leaderboard-table__row-item-with-avatar':item.avatar}"
          >
            {{ item.username }}
          </div>
          <div class="c-leaderboard-table__row-item is-for-points">
            {{ $filters.formatNumber( item.points  ) }}
          </div>
          <div
            :class="[
              'c-leaderboard-table__row-item is-for-prize',
              { 'is-green': item.prize },
            ]"
          >
            {{ item.prize?item.prize.toLocaleString():0 }}
          </div>
        </div>
        <div
          v-if="$store.getters.getUsername && !isTop10 && $store.getters.getLoginData.loginMethod !== 'guest'"
          class="c-leaderboard-table__row is-for-me"
        >
          <div class="c-leaderboard-table__row-item is-for-number">
            <div class="c-circle c-circle--gray c-circle--is-for-me">
              <span class="c-circle__value">{{ "-" }}</span>
            </div>
          </div>
          <div
            class="c-leaderboard-table__row-item is-for-bettor is-primary-bold"
          >
            {{ $store.getters.getDiscordUsername? $store.getters.getDiscordUsername : $store.getters.getUserId }}
          </div>
          <div class="c-leaderboard-table__row-item is-for-points">
            {{ $filters.formatNumber( leaderboard.myPoints  ) }}
          </div>
          <div class="c-leaderboard-table__row-item is-green is-for-prize">
            {{ "-" }}
          </div>
        </div>
        <div
          v-else-if="$store.getters.getIsGuest"
          class="c-leaderboard-table__row is-for-me"
          style="font-family: 'Rubik', sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 15px;
                line-height: 130%;
                text-align: center;
                cursor:pointer;
                letter-spacing: -0.03em;"
          @click="$store.commit('setGuestGuideOpen', true)"
        >
          Signup to join the leaderboard and win extra chips
        </div>
      </div>
      <div class="c-leaderboard-table__category">
        <div
          v-if="!showCurrent"
          class="c-leaderboard-table__pool-previous-btn"
          @click="selectCurrentLeaderboard(true)"
        >
          See current {{ leaderboardCategory===0?'Hour':leaderboardCategory===1?'Day':'Week' }}
        </div>
        <div
          v-if="showCurrent"
          class="c-leaderboard-table__pool-previous-btn"
          @click="selectCurrentLeaderboard(false)"
        >
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.42394 2.57582C9.31142 2.46334 9.15884 2.40015 8.99974 2.40015C8.84064 2.40015 8.68805 2.46334 8.57554 2.57582L5.57554 5.57582C5.46305 5.68834 5.39987 5.84092 5.39987 6.00002C5.39987 6.15912 5.46305 6.3117 5.57554 6.42422L8.57554 9.42422C8.6887 9.53351 8.84026 9.59399 8.99758 9.59262C9.1549 9.59126 9.30538 9.52815 9.41663 9.41691C9.52787 9.30567 9.59098 9.15518 9.59234 8.99786C9.59371 8.84054 9.53323 8.68898 9.42394 8.57582L6.84814 6.00002L9.42394 3.42422C9.53642 3.3117 9.59961 3.15912 9.59961 3.00002C9.59961 2.84092 9.53642 2.68834 9.42394 2.57582ZM5.82394 2.57582C5.71142 2.46334 5.55884 2.40015 5.39974 2.40015C5.24064 2.40015 5.08805 2.46334 4.97554 2.57582L1.97554 5.57582C1.86305 5.68834 1.79987 5.84092 1.79987 6.00002C1.79987 6.15912 1.86305 6.3117 1.97554 6.42422L4.97554 9.42422C5.0887 9.53351 5.24026 9.59399 5.39758 9.59262C5.5549 9.59126 5.70538 9.52815 5.81663 9.41691C5.92787 9.30567 5.99097 9.15518 5.99234 8.99786C5.99371 8.84054 5.93323 8.68898 5.82394 8.57582L3.24814 6.00002L5.82394 3.42422C5.93642 3.3117 5.99961 3.15912 5.99961 3.00002C5.99961 2.84092 5.93642 2.68834 5.82394 2.57582Z" fill="#FFE500"/>
          </svg>

          See previous {{ leaderboardCategory===0?'Hour':leaderboardCategory===1?'Day':'Week' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "LeaderboardBattle",
  data() {
    return {
      showCurrent: true,
      leaderboardCategory: 0,
    };
  },
  computed: {
    leaderboardPrize() {
      //MyLog(this.$store.state.leaderboardPrizeHourly)
      //MyLog(this.$store.state.leaderboardPrizeDaily)
      //MyLog(this.$store.state.leaderboardPrizeWeekly)
      switch (this.leaderboardCategory) {
        case 0:
          return this.$store.state.leaderboardPrizeHourly['slotbattle'];
        case 1:
          return this.$store.state.leaderboardPrizeDaily['slotbattle'];
        case 2:
          return this.$store.state.leaderboardPrizeWeekly['slotbattle'];
      }
      return 0;
    },
    leaderboard() {
      const state=this.$store.state;
      let lb=state.leaderboard['slotbattle']
      const fb=state.leaderboard;
      if (!lb) {
        return { users: [], myPoints: 0 };
      }

      switch (this.leaderboardCategory) {
        case 0:
          return this.showCurrent? lb.current.hourly : lb.previous.hourly;
        case 1:
          return this.showCurrent? lb.current.daily : lb.previous.daily;
        case 2:
          return this.showCurrent? lb.current.weekly : lb.previous.weekly;
      }

      return { users: [], myPoints: 0 };
    },
    isTop10() {
      if (!this.leaderboard) {
        return false;
      }
      for (let i = 0; i < this.leaderboard.users.length; i++) {
        if (
          this.leaderboard.users[i].username === this.$store.getters.getDiscordUsername || this.leaderboard.users[i].username === this.$store.getters.getUserId
        ) {
          return true;
        }
      }
      return false;
    },
  },
  created() {
    //this.$store.dispatch("getLeaderboard");
  },
  methods:{
    selectLeaderboard(category) {
      this.leaderboardCategory = category;
    },
    selectCurrentLeaderboard(bool) {
      this.showCurrent = bool;
    },
    getPrize(i) {
      switch (this.leaderboardCategory) {
        case 0:
          return this.$store.getters.getLeaderboardPrizeSplit.length > i? this.$store.getters.getLeaderboardPrizeHourly * this.$store.getters.getLeaderboardPrizeSplit[i] / 100 : 0;
        case 1:
          return this.$store.getters.getLeaderboardPrizeSplit.length > i? this.$store.getters.getLeaderboardPrizeDaily * this.$store.getters.getLeaderboardPrizeSplit[i] / 100 : 0;
        case 2:
          return this.$store.getters.getLeaderboardPrizeSplit.length > i? this.$store.getters.getLeaderboardPrizeWeekly * this.$store.getters.getLeaderboardPrizeSplit[i] / 100 : 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/components/filter-date";
@import "../../assets/styles/components/app-circle";
@import "../../assets/styles/components/leaderboard-table";
.c-leaderboard-battle__pool-line{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.c-leaderboard-battle__pool-update{
  width: 380px;
  height: 43px;
  left: 1210px;
  top: 865px;

  /* white */
  background-color: rgba(255,255,255,0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  @include media-max(900px) {
    width: 100%;
  }
}
</style>
