<template>
    <div class="c-battle_top">
        <!--    Battle screen  -->
        <div class="c-battle_main"
             :style="{'width': battleWidth+'px','height': battleHeight+'px','border-radius': 20*ss+'px',}">
            <!-- region with h/w=0.5625 the same as 1080/1920 -->
            <div class="c-battle_main"
                 :style="{'width': realWidth+'px','height': realHeight+'px',}">
                <!-- Main iFrame -->
                <iframe id="battle_main"
                        style="width: 100%;height: 100%;border: none;background: black;"
                        :src="gameUri+'battle'"
                />

                <!-- iFrame left (my player) -->
                <div v-if="iFrame1Visible" class="c-battle_frame"
                     :style="{'width': iFrameH*0.5625*ss+'px','height': iFrameH*ss+'px','left': 290*ss+'px','top': 170*ss+'px'}">
                    <iframe id="battle_frame1"
                            style="width: 100%;height: 100%;border: none;background: black;"
                            :src="iFrame1SlotUri"
                    />
                </div>
                <!-- iFrame right (opponent) -->
                <div v-if="iFrame2Visible" class="c-battle_frame"
                     :style="{'width': iFrameH*0.5625*ss+'px','height': iFrameH*ss+'px','right': 290*ss+'px','top': 170*ss+'px'}">
                    <iframe id="battle_frame2"
                            style="width: 100%;height: 100%;border: none;background: black;"
                            :src="iFrame2SlotUri"
                    />
                </div>

                <!-- Click to continue popup -->
                <div v-if="battleResult" @click="this.clickContinue()"
                        class="c-battle_frame" style="background: none">
                    <div class="c-battle_main" style="background: none"
                         :style="{'width': 600*ss+'px','height': 370*ss+'px'}">
                    >
                        <img :src="battleResult.winner==='me'?popup_you_won:popup_you_lose" alt="" style="width: 100%; height: 100%">
                        <div class="c-battle_frame" style="background: none"
                             :style="{'width': 600*ss+'px','height': 80*ss+'px'}">
                            <div v-if="battleResult.winner==='me'" class="c-chips_text"
                                 :style="{'font-size': 35*ss+'px','line-height': 50*ss+'px','bottom': 0*ss+'px'}">
                                {{battleResult.totalSlotBattleChipsWon+' CHIPS'}}
                            </div>
                        </div>
                        <div class="c-battle_frame" style="background: none"
                             :style="{'width': 331*ss+'px','height': 40*ss+'px','bottom': 50*ss+'px'}">
                            <img :src="txt_click_continue" alt="" style="width: 100%; height: 100%"
                                 :style="{'opacity': continueAlpha}"
                            >
                        </div>
                    </div>
                </div>

            </div>

            <UnlockConfirm v-if="popupUnlockConfirm.visible===true"
                           :callback="this.unlockFinish"
                           :ss="ss"
                           :real-width="battleWidth"
                           :real-height="battleHeight"
            />

            <error-popup
                    :open="errorPopup"
                    :title="titleErrorPopup"
                    :text="textErrorPopup"
                    @closeErrorPopup="errorPopup=false"
            />

            <!--    Rules popup -->
            <div v-if="rulesVisible" class="c-battle_rules"
                 @click="clickRules"
            >
                <div class="c-battle_rules_reg"
                     :style="{'width': battleWidth*0.8+'px','height': battleHeight*0.9+'px',}"
                >
                    <div class="c-battle_rules_text" style="justify-content: center;font-weight: 700;"
                         :style="{'font-size': 45*ss+'px','line-height': 65*ss+'px',}"
                    >How to Play:</div>

                    <div class="c-battle_rules_text" style="font-weight: 600;"
                         :style="{'font-size': 35*ss+'px','line-height': 50*ss+'px',}"
                    >1. Choose Your Slot:</div>
                    <div class="c-battle_rules_text" style="font-weight: 400;"
                         :style="{'font-size': 35*ss+'px','line-height': 50*ss+'px','padding-left': 50*ss+'px',}"
                    >- Select the slot machine you want to play on.</div>

                    <div class="c-battle_rules_text" style="font-weight: 600;"
                         :style="{'font-size': 35*ss+'px','line-height': 50*ss+'px','margin-top': 10*ss+'px',}"
                    >2. Place Your Bet:</div>
                    <div class="c-battle_rules_text" style="font-weight: 400;"
                         :style="{'font-size': 35*ss+'px','line-height': 50*ss+'px','padding-left': 50*ss+'px',}"
                    >- Choose the amount of chips you want to bet: 1000/5000/10000.</div>

                    <div class="c-battle_rules_text" style="font-weight: 600;"
                         :style="{'font-size': 35*ss+'px','line-height': 50*ss+'px','margin-top': 10*ss+'px',}"
                    >3. Launch Battle:</div>
                    <div class="c-battle_rules_text" style="font-weight: 400;"
                         :style="{'font-size': 35*ss+'px','line-height': 50*ss+'px','padding-left': 50*ss+'px',}"
                    >- Click on "Launch Battle" to start the game.</div>

                    <div class="c-battle_rules_text" style="font-weight: 600;"
                         :style="{'font-size': 35*ss+'px','line-height': 50*ss+'px','margin-top': 30*ss+'px',}"
                    >Gameplay Instructions:</div>
                    <div class="c-battle_rules_text" style="font-weight: 400;"
                         :style="{'font-size': 35*ss+'px','line-height': 50*ss+'px','padding-left': 50*ss+'px',}"
                    >- The game will automatically begin once an opponent is found.</div>
                    <div class="c-battle_rules_text" style="font-weight: 400;"
                         :style="{'font-size': 35*ss+'px','line-height': 50*ss+'px','padding-left': 50*ss+'px',}"
                    >- If no real player joins within 30 seconds, you will play against BadBot.</div>
                    <div class="c-battle_rules_text" style="font-weight: 400;"
                         :style="{'font-size': 35*ss+'px','line-height': 50*ss+'px','padding-left': 50*ss+'px',}"
                    >- The game will feature a random number of spins (25, 50 or 125) to ensure fairness and not favor any specific slot volatility.</div>

                    <div class="c-battle_rules_text" style="font-weight: 600;"
                         :style="{'font-size': 35*ss+'px','line-height': 50*ss+'px','margin-top': 30*ss+'px',}"
                    >Winning the Game:</div>
                    <div class="c-battle_rules_text" style="font-weight: 400;"
                         :style="{'font-size': 35*ss+'px','line-height': 50*ss+'px','padding-left': 50*ss+'px',}"
                    >- At the end of all the spins, the player with the highest number of chips wins.</div>
                    <div class="c-battle_rules_text" style="font-weight: 400;"
                         :style="{'font-size': 35*ss+'px','line-height': 50*ss+'px','padding-left': 50*ss+'px',}"
                    >- The winner takes all the chips  including those of the opponent (less 5% rake rate). </div>
                </div>
            </div>
        </div>

        <!--  leaderboard, etc if need  -->
        <LeaderboardBattle style="max-width: 1400px;"
                :style="{'width': '95%','height': 'auto','margin-top': 40*ss+'px','margin-bottom': 40*ss+'px',}"
        />
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {allGames, GameUri} from "../../constants/index";
    import Backend from "../../classes/backend.js";
    import errorPopup from "@/components/popups/error-popup.vue";

    import popup_you_lose from "@/assets/images/battle/popup_you_lose.webp"
    import popup_you_won from "@/assets/images/battle/popup_you_won.webp"
    import txt_click_continue from "@/assets/images/battle/txt_click_continue.webp"
    import sound_battle_ui from "@/assets/audio/sound_battle_ui.mp3"
    import LeaderboardBattle from "./LeaderboardBattle.vue";
    import UnlockConfirm from "./UnlockConfirm.vue";

    export default {
        name: "BattlePage",
        components: {
            errorPopup,LeaderboardBattle,UnlockConfirm,
        },
        data() {
            return {
                allGames: allGames, gameUri: GameUri,
                nextUser: {},
                isMobile: false,
                realHeight: 1080, realWidth: 1920, ss: 1,    //size of iFrame
                battleWidth: 1920, battleHeight: 1080,     //size of c-battle_main
                battleMain: null, battleMainWin: null,
                iFrameH: 840, iFrame1Visible: false, iFrame2Visible: false,
                roomBet: 0, iFrame1Slot: 'copacabana', iFrame2Slot: 'copacabana',
                iFrame1SlotUri: '', iFrame2SlotUri: '',
                popup_you_lose:popup_you_lose,popup_you_won:popup_you_won,txt_click_continue:txt_click_continue,
                me: {
                    game: '',
                    balance: 0,
                    userName: this.$store.state.username,
                    chipsWonSoFar: 0,
                    spin: 0,
                    spinsLeft: 0,
                    initSpin:null,          //Get init message through Backend.getplayerslotbattlespin
                    finish:false,           //if both players finish battle, than send BattleResult on last animationsComplete
                },
                opponent: {
                    game: '',
                    balance: 0,
                    userName: '',
                    chipsWonSoFar: 0,
                    spin: 0,
                    spinsLeft: 0,
                    initSpin:null,
                    finish:false,
                },
                askForResult:false,                 //if true don't send second message "getplayerslotbattleresult"
                betPerSpin:0,
                battleResult:null,//{"totalChipsWon1":5114,"totalChipsWon2":650,"totalSlotBattleChipsWon":5764,"balance":6599584,"balance1":6599584,"balance2":10734770,"winner":"me"},
                errorPopup: false,
                textErrorPopup: "",
                titleErrorPopup: "Error",
                continueAlpha:1,                  //Alpha for click_to_continue text
                sound_ui:null,
                rulesVisible:false,
            };
        },
        computed: {
            ...mapGetters(["popupUnlockConfirm"]),
            gameName() {
                return '';
            },
            loggedIn() {
                return this.$store.getters.getLoginData != null;
            },
            chips() {
                return this.$store.getters.getChipsBalance;
            },
            unlockedGames() {
                return this.$store.getters.getUnlockedGames;
            }
        },
        watch: {
            async loggedIn(value) {
                if (this.initOnLogin && value) {
                    let initData = await Backend.init(
                        this.allGames[this.$route.params.gameId].name,
                        this.$store.getters.getLoginData
                    );
                    if (
                        initData.error === "User not found" ||
                        initData.error === "Authentication Error" ||
                        initData.error === "Auth Token Expired"
                    ) {
                        this.$store.dispatch("logout");
                    } else {
                        this.gameWindow.postMessage(JSON.stringify(initData), "*");
                        this.initOnLogin = false;
                        if (initData.balance) {
                            this.$store.commit("setChips", initData.balance);
                        }
                    }
                } else if (!value) {
                    this.$router.push('/');
                }
            },
            chips(value) {

            },
            unlockedGames(value) {
                /*if (!value.includes(this.gameName)) {
                    this.$router.push('/');
                }//*/
            }
        },
        async created() {
            const loginData=this.$store.getters.getLoginData;
            if (!loginData) {
                this.$router.push('/');
            }
            this.sound_ui=new Audio(sound_battle_ui);
            console.log("SlotBattle page created");
        },
        beforeUnmount() {
            window.removeEventListener("message", this.receiveMessage);
            window.removeEventListener('scroll', this.handleScroll);
            window.removeEventListener('resize', this.resize.bind(this));
            this.battleResult=null;
        },
        beforeMount() {
            if (!localStorage.getItem("loginData")) {
                this.$router.push('/');
            }
            window.addEventListener("message", this.receiveMessage, false);
            window.addEventListener('scroll', this.handleScroll);
            window.addEventListener('resize', this.resize.bind(this));
            this.resize();

        },
        mounted() {
            if(!localStorage.getItem("loginData")) {
                this.$router.push('/');
            }
            //debugger
            this.scrollUp();
            this.$store.dispatch("getUser","chips");
            this.me.balance = this.$store.state.chips;
        },
        methods: {
            clickContinue() {
                this.battleResult=null;

                const battleMain = document.getElementById('battle_main');
                const battleMainWin = battleMain.contentWindow;
                const obj = {action: 'battleClickContinue'};
                this.cleanGame();
                battleMainWin.postMessage(JSON.stringify(obj), "*");
                if (this.sound_ui && this.$store.getters.getSound)
                    this.sound_ui.play();

            },
            clickRules(){
                this.rulesVisible=false;
            },
            continueBlinkStart(){
                const continueTl=TweenMax.timeline({repeat: -1, repeatDelay: 0, onUpdate:()=>{
                        if (!this.battleResult){
                            continueTl.kill();
                        }
                    }
                });
                continueTl.to(this, {continueAlpha: 0.4, duration: 1});
                continueTl.to(this, {continueAlpha: 1, duration: 1}, ">");
            },
            cleanGame(){            //Set vars to initial state after game
                this.me= {
                    game: '',
                        balance: 0,
                        userName: this.$store.state.username,
                        chipsWonSoFar: 0,
                        spin: 0,
                        spinsLeft: 0,
                        initSpin:null,          //Get init message through Backend.getplayerslotbattlespin
                        finish:false,           //if both players finish battle, than send BattleResult on last animationsComplete
                };
                this.opponent= {
                    game: '',
                        balance: 0,
                        userName: '',
                        chipsWonSoFar: 0,
                        spin: 0,
                        spinsLeft: 0,
                        initSpin:null,
                        finish:false,
                };
                this.askForResult=false;
                this.betPerSpin=0
            },
            getGames() {
                const games = {};
                try {
                    const keys = Object.keys(allGames);
                    keys.forEach((key) => {
                        const gameC = allGames[key];
                        if (gameC.battle === true) {
                            const game = JSON.parse(JSON.stringify(gameC));
                            game.description = this.$t('general.' + gameC.description)
                            game.imageLink = this.getIconPath(game.thumb);
                            game.locked=this.isGameLocked(key);
                            games[key] = game;
                        }
                    });
                } catch (e) {
                    console.log('battle-page getGames catch: ', e.message, '\nstack: ', e.stack)
                }
                return games;
            },
            //It should return {rooms: [{roomBet: 1000, players: 0},{roomBet: 5000, players: 1},{roomBet: 10000, players: 1}]}
            async getRooms(initData) {
                let obj = null;
                try {
                    //obj={rooms: [{roomBet: 1000, players: 0},{roomBet: 5000, players: 1},{roomBet: 10000, players: 1}]};
                    obj = await Backend.getSlotBattleRooms(this.$store.getters.getLoginData);
                    //obj=await Backend.getPlayerSlotBattleStatus(this.$store.getters.getLoginData);
                    if (obj) {
                        initData.rooms = obj.rooms;
                        initData.error = obj.error ? 'Backend.getSlotBattleRooms return error: ' + obj.error : undefined;
                        initData.success = obj.success;
                    } else {
                        initData.error = 'Backend.getSlotBattleRooms return null';
                        initData.success = false;
                    }
                    if (initData.error) {
                        this.errorPopup = true;
                        this.textErrorPopup = initData.error;
                    }
                } catch (e) {
                    initData.error = 'battle-page getRooms catch: ' + e.message + '\nstack: ' + e.stack;
                    initData.success = false;
                    console.log(initData.error)
                }
            },
            getIconPath(iconName) {
                let txt = '';
                try {
                    txt = iconName ? require(`../../assets/images/${iconName}`) : "";
                    txt = txt ? GameUri + txt : "";
                } catch (e) {
                }
                return txt;
            },
            isGameLocked(name){
                if(name==="tycoon")return false
                return /*this.isTest&&this.page==="scratch"?false:*/this.$store.getters.getUsername?!this.$store.getters.getUnlockedGames.includes(name):false;
            },
            messageSelectSlot(data, message) {
                Backend.getSlotBattleRooms(this.$store.getters.getLoginData).then(ans => {
                    const rooms = ans.rooms;
                    const obj = {action: 'selectSlot', game: data.game, rooms: rooms};
                    message.source.postMessage(JSON.stringify(obj), "*");
                })
            },
            messageSelectRoom(data, message) {
                Backend.joinSlotBattle(data.game, data.roomBet, this.$store.getters.getLoginData).then(ans => {
                    MyLog(JSON.stringify(ans));
                    let success = ans.error ? false : true;
                    if (!success){
                        this.errorPopup= true;
                        this.textErrorPopup="Backend return: "+ans.error;
                    }
                    const obj = {
                        action: 'selectRoom',
                        game: data.game,
                        roomBet: data.roomBet,
                        success: success,
                        error: ans.arror
                    };
                    this.iFrame1Slot = obj.game;
                    this.roomBet = obj.roomBet;
                    message.source.postMessage(JSON.stringify(obj), "*");
                    if (success) {
                        const sobj = {action: 'battleInfo', slotBattleStatus: ans.slotBattleStatus};
                        this.processBattleStatus(ans.slotBattleStatus);
                        message.source.postMessage(JSON.stringify(sobj), "*");
                    }
                    this.$store.dispatch("getUser","chips");
                    setTimeout(()=>{this.$store.dispatch("getUser","chips");},62000)
                });
            },
            processBattleStatus(slotBattleStatus) {
                const status = slotBattleStatus.status;
                if (status === 'idle') {
                    this.iFrame1Visible = false;
                    this.iFrame2Visible = false;
                }
                if (status === 'waiting') {
                    this.iFrame1Visible = false;
                    this.iFrame2Visible = false;
                }
                if (status === 'battle') {
                    this.me.spin = slotBattleStatus.numSpinsWatched1;
                    this.opponent.spin = slotBattleStatus.numSpinsWatched2;
                    this.me.spinsLeft = slotBattleStatus.numSpinsToWatch1-slotBattleStatus.numSpinsWatched1;
                    this.opponent.spinsLeft = slotBattleStatus.numSpinsToWatch2-slotBattleStatus.numSpinsWatched2;
                    this.iFrame1Slot = slotBattleStatus.game1;
                    this.iFrame2Slot = slotBattleStatus.game2;
                    this.iFrame1SlotUri = allGames[this.iFrame1Slot].uri;
                    this.iFrame2SlotUri = allGames[this.iFrame2Slot].uri;
                    this.iFrame1Visible = true;
                    this.iFrame2Visible = true;
                    this.betPerSpin=slotBattleStatus.betPerSpin;
                    this.me.chipsWonSoFar=slotBattleStatus.chipsWonSoFar1;
                    this.opponent.chipsWonSoFar=slotBattleStatus.chipsWonSoFar2;
                }
            },
            async receiveMessage(message) {
                //battle
                try {
                    if (typeof message.data !== "string") {
                        return;
                    }
                    let data = null;
                    try {
                        data = JSON.parse(message.data);
                    } catch (e) {
                    }
                    if (!data) return;

                    //debugger
                    //Process message from Main iFrame
                    const battleMain = document.getElementById('battle_main');
                    const battleMainWin = battleMain.contentWindow;
                    const iFrame1 = document.getElementById('battle_frame1');
                    const iFrame1Win = iFrame1 ? iFrame1.contentWindow : null;
                    const iFrame2 = document.getElementById('battle_frame2');
                    const iFrame2Win = iFrame2 ? iFrame2.contentWindow : null;
                    if (battleMainWin === message.source) {
                        MyLog('message from main iFrame: ' + message.data);
                        await this.sendAnswerMain(data, message);
                    }
                    if (iFrame1Win === message.source) {
                        MyLog('message from iFrame1: ' + message.data);
                        await this.sendAnswerFrame(data, message, 'me');
                    }
                    if (iFrame2Win === message.source) {
                        MyLog('message from iFrame2: ' + message.data);
                        await this.sendAnswerFrame(data, message, 'opponent');
                    }
                } catch (e) {
                    console.log('battle-page receiveMessage catch: ', e.message, '\nstack: ', e.stack)
                }
            },
            resize() {
                let appWidth = window.innerWidth - 16;       //16 scrollbar fixed size
                let appHeight = window.innerHeight - 90;      //90 blockspin logo fixed height
                let orient = appWidth < appHeight ? 'V' : 'H';

                if (appHeight / appWidth > 0.5625)
                    appHeight = appWidth * 0.5625;

                let perW = appWidth / 1920;         //70px height of blockspin logo
                let perH = appHeight / 1080;
                let ss = Math.min(perW, perH);
                if (perW < perH) {
                    this.realWidth = appWidth;
                    this.realHeight = 1080 * perW;
                } else {
                    this.realWidth = 1920 * perH;
                    this.realHeight = appHeight;
                }

                this.ss = ss;
                this.battleWidth = appWidth;
                this.battleHeight = appHeight;
            },

            scrollBot() {
                window.scroll(0, 0);
            },
            scrollUp() {
                const scrollTo = document.getElementById("header");
                if (scrollTo) {
                    scrollTo.scrollIntoView();
                }
            },
            scrollTop() {
                window.scrollBy(0, 300);
            },
            sendBattleState(player,spin) {
                try {
                    if (!spin) return;
                    MyLog('sendBattleState');
                    const state = this.$store.state;
                    const battleMain = document.getElementById('battle_main');
                    const battleMainWin = battleMain.contentWindow;

                    const obj = {action: 'battleStatus', slotBattleStatus: spin.slotBattleStatus};
                    this.me.balance=spin.slotBattleStatus.balance1;
                    this.me.chipsWonSoFar=spin.slotBattleStatus.chipsWonSoFar1;      //chipsWonSoFar1
                    this.opponent.balance=spin.slotBattleStatus.balance2;
                    this.opponent.chipsWonSoFar=spin.slotBattleStatus.chipsWonSoFar2;

                    battleMainWin.postMessage(JSON.stringify(obj), "*");

                } catch (e) {
                    console.log('battle-page sendBattleState catch: ', e.message, '\nstack: ', e.stack)
                }
            },
            async sendAnswerMain(data, message) {
                try {
                    if (data.action === 'init') {
                        const initData = {action: 'init'};
                        initData.sound = this.$store.getters.getSound;
                        initData.games = this.getGames();
                        initData.userName = this.$store.state.username;
                        initData.userAvatar = this.$store.state.avatar;
                        await this.getRooms(initData);
                        message.source.postMessage(JSON.stringify(initData), "*");
                    }
                    if (data.action === 'battleInfo') {
                        const ans = await Backend.getPlayerSlotBattleStatus(this.$store.getters.getLoginData);
                        MyLog('Backend.getPlayerSlotBattleStatus:');
                        MyLog(JSON.stringify(ans));
                        const obj = {action: 'battleInfo', slotBattleStatus: ans.slotBattleStatus};
                        this.processBattleStatus(ans.slotBattleStatus);
                        message.source.postMessage(JSON.stringify(obj), "*");
                    }
                    if (data.action === 'selectSlot') {
                        this.messageSelectSlot(data, message);
                    }
                    if (data.action === 'selectRoom') {
                        this.messageSelectRoom(data, message);
                    }
                    if (data.action === 'battleFinish') {
                        this.battleResult=data.battleResult;
                        this.continueBlinkStart();
                    }
                    if (data.action === 'soundOn') {
                        this.$store.commit("setSound", "on");
                    }
                    if (data.action === 'soundOff') {
                        this.$store.commit("setSound", "off");
                    }
                    if (data.action === 'rules') {
                        this.rulesVisible=true;
                    }
                    if (data.action === 'unlockGame') {
                        this.$store.state.popupUnlockConfirm.gameObj=data.gameObj;
                        this.$store.state.popupUnlockConfirm.visible=true;
                    }
                }
                catch (e) {
                    console.log('battle-page sendAnswerMain catch: ', e.message, '\nstack: ', e.stack)
                }
            },
            async sendAnswerFrame(data, message, player) {
                try {
                    const gameName = player === 'me' ? this.iFrame1Slot : this.iFrame2Slot;
                    let time1=0;
                    let time2=0;
                    switch (data.action) {
                        case 'init': {
                            this.gameWindow = message.source;
                            if (this.$store.getters.getLoginData) {
                                console.log('init Backend.getplayerslotbattlespin init, player: '+player+' | spinNumber: '+this[player].spin);
                                time1=Date.now();
                                const spin = await Backend.getplayerslotbattlespin(this[player].spin, player === 'opponent', this.$store.getters.getLoginData);
                                time2=Date.now();
                                console.log('init Backend.getplayerslotbattlespin ready, player: '+player+' | spinNumber: '+this[player].spin+' | '+ (time2-time1)/1000 + 's');
                                console.log(JSON.stringify(spin));
                                if (spin.error) {
                                    this.errorPopup= true;
                                    this.textErrorPopup="Backend return: "+spin.error;
                                } else {
                                    this[player].initSpin=spin;
                                    const initData=spin.initData;
                                    initData["sound"] = false;
                                    let bet=initData.params.linesPossible[0]?initData.params.linesPossible[0]:1;
                                    bet=this.betPerSpin/bet;
                                    initData.params.betPossible.push(bet);
                                    initData.battle={spinsLeft:this[player].spinsLeft,betPerSpin:this.betPerSpin};
                                    message.source.postMessage(JSON.stringify(initData), "*");

                                }
                            } else {
                                this.initOnLogin = true;
                            }
                            break;
                        }
                        case 'spin': {
                            if (!this.$store.getters.getIsGuestAndOutOfBets && this[player].spinsLeft>0) {
                                const current = data.current;
                                let spin;
                                time1=0;
                                time2=0;
                                if (this[player].initSpin){
                                    spin=this[player].initSpin;
                                    this[player].initSpin=null;
                                }
                                else {
                                    console.log('spin Backend.getplayerslotbattlespin, player: '+player+' | spinNumber: '+this[player].spin);
                                    time1=Date.now();
                                    spin = await Backend.getplayerslotbattlespin(this[player].spin, player === 'opponent', this.$store.getters.getLoginData);
                                    time2=Date.now();
                                }
                                console.log('spin Backend.getplayerslotbattlespin ready, player:'+player+' | spinNumber: '+this[player].spin+' | '+ (time2-time1)/1000 + 's');
                                console.log(JSON.stringify(spin));
                                if (spin.error) {
                                    this.errorPopup= true;
                                    this.textErrorPopup="Backend return: "+spin.error;
                                }
                                else {
                                    const slotBattleStatus=spin.slotBattleStatus;

                                    this[player].spin = this[player].spin+1;
                                    if (player==='me'){
                                        this[player].spinsLeft=slotBattleStatus.numSpinsToWatch1-this[player].spin;
                                    }
                                    else{
                                        this[player].spinsLeft=slotBattleStatus.numSpinsToWatch2-this[player].spin;
                                    }

                                    //this.me.spinsLeft=slotBattleStatus.numSpinsToWatch1-slotBattleStatus.numSpinsWatched1;
                                    //this.opponent.spinsLeft = slotBattleStatus.numSpinsToWatch2-slotBattleStatus.numSpinsWatched2;
                                    //this[player].spin = player==='me'?slotBattleStatus.numSpinsWatched1:slotBattleStatus.numSpinsWatched2;

                                    let spinData = spin.betData?
                                        spin.betData:
                                        (spin.bonusData?spin.bonusData:{});
                                    spinData.battle={spinsLeft:this[player].spinsLeft};
                                    message.source.postMessage(JSON.stringify(spinData), "*");

                                    this.sendBattleState(player,spin);
                                }
                            }
                            else {
                                this.$store.commit("setNumBets", this.$store.getters.getNumBets + 1);
                            }
                            break;
                        }
                        case 'bonus': {
                            let spin;
                            if (this[player].initSpin){
                                spin=this[player].initSpin;
                                this[player].initSpin=null;
                            }
                            else {
                                console.log('bonus get spin, player: ' + player + ' | spinNumber: ' + this[player].spin);
                                spin = await Backend.getplayerslotbattlespin(this[player].spin, player === 'opponent', this.$store.getters.getLoginData);
                            }
                            console.log('bonus Backend.getplayerslotbattlespin, player:' + player + ' | spinNumber: ' + this[player].spin);
                            console.log(JSON.stringify(spin));

                            if (spin.error) {
                                this.errorPopup= true;
                                this.textErrorPopup="Backend return: "+spin.error;
                            } else {
                                let bonusData = spin.bonusData;
                                const slotBattleStatus=spin.slotBattleStatus;

                                this[player].spin = this[player].spin+1;
                                if (player==='me'){
                                    this[player].spinsLeft=slotBattleStatus.numSpinsToWatch1-this[player].spin;
                                }
                                else{
                                    this[player].spinsLeft=slotBattleStatus.numSpinsToWatch2-this[player].spin;
                                }

                                //this.me.spinsLeft=slotBattleStatus.numSpinsToWatch1-slotBattleStatus.numSpinsWatched1;
                                //this.opponent.spinsLeft = slotBattleStatus.numSpinsToWatch2-slotBattleStatus.numSpinsWatched2;
                                //this[player].spin = player==='me'?slotBattleStatus.numSpinsWatched1:slotBattleStatus.numSpinsWatched2;

                                bonusData.battle={spinsLeft:this[player].spinsLeft};
                                message.source.postMessage(JSON.stringify(bonusData), "*");

                                this.sendBattleState(player,spin);
                            }
                            break;
                        }
                        case 'animationsComplete': {
                            if (this.askForResult)
                                break;
                            MyLog('case animationsComplete: ');
                            const battleMain = document.getElementById('battle_main');
                            const battleMainWin = battleMain.contentWindow;

                            const obj = {action: 'animationsComplete',
                                score1: player==='me'?this.me.chipsWonSoFar:null,
                                score2: player==='opponent'?this.opponent.chipsWonSoFar:null,
                            };
                            if (this[player].spinsLeft===0)
                                this[player].finish=true;

                            if (this.me.finish && this.opponent.finish) {
                                console.log('Ask Backend for battle result:');
                                this.askForResult=true;
                                const ans = await Backend.getplayerslotbattleresult(this.$store.getters.getLoginData);
                                console.log('Backend.getplayerslotbattleresult:');
                                console.log(JSON.stringify(ans));
                                if (ans.error){
                                    this.errorPopup= true;
                                    this.textErrorPopup="Backend.getplayerslotbattleresult return: "+ans.error;
                                }
                                else{
                                    obj.battleResult=ans;
                                }
                                this.$store.dispatch("getUser");
                            }

                            battleMainWin.postMessage(JSON.stringify(obj), "*");

                            break;
                        }
                    }
                } catch (e) {
                    console.log('battle-page sendAnswerFrame1 catch: ', e.message, '\nstack: ', e.stack)
                }//*/
            },

            unlockFinish(obj){
                MyLog('unlockFinish');
                MyLog(JSON.stringify(obj));
                if (obj.success===true){
                    const battleMain = document.getElementById('battle_main');
                    const battleMainWin = battleMain.contentWindow;
                    battleMainWin.postMessage(JSON.stringify(obj), "*");
                    MyLog(JSON.stringify(obj));
                }
                else{
                    this.textErrorPopup=obj.error;
                    this.errorPopup=true;
                }
            },
            handleScroll(event) {
                //console.log(event);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .c-battle_top{
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: no-display;
        justify-content: flex-start;
        align-items: center;
        width: auto;
        height: auto;
    }
    .c-battle_main {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: black;
        overflow: hidden;
    }

    .c-battle_frame {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: black;
        overflow: hidden;
    }

    .c-chips_text{
        box-sizing: border-box;
        position: absolute;

        font-family: 'Poppins',sans-serif;
        font-style: normal;
        font-weight: 800;

        background: linear-gradient(95.19deg, #b3f4f8 -16.14%, #8bf5fc 137.96%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 0px 0px 15px rgba(57, 211, 231, 0.8);


    }

    .c-battle_rules{
        position: absolute;
        top:0;
        left:0;
        display: flex;
        flex-direction: column;
        overflow: no-display;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.8);
    }
    .c-battle_rules_reg{
        position: relative;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
        justify-content: center;
        align-items: center;
        /*background: rgba(255,255,255,0.4);/**/
    }
    .c-battle_rules_text{
        display: flex;
        width: 100%;
        height: auto;
        flex-direction: row;
        overflow: hidden;
        justify-content: flex-start;
        font-weight: 500;
        align-items: center;
        color: white;
        font-family: 'Poppins',sans-serif;
        font-style: normal;
    }
</style>
